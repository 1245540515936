(function() {
    var SMSLink = {};
    SMSLink.detector = (function () {
        var ua = navigator.userAgent;
        var os;
        var version;

        var uaIndex;

        // determine OS
        if (ua.match(/iPad/i) || ua.match(/iPhone/i)) {
            os = 'iOS';
            uaIndex = ua.indexOf('OS ');
        }
        else if (ua.match(/Android/i)) {
            os = 'Android';
            uaIndex = ua.indexOf('Android ');
        }
        else {
            os = null;
        }

        // determine version
        if (os === 'iOS' && uaIndex > -1) {
            version = ua.substr(uaIndex + 3, 3).replace('_', '.');
        }
        else if (os === 'Android' && uaIndex > -1) {
            os = ua.substr(uaIndex + 8, 3);
        }
        else {
            version = null;
        }

        return {
            version: function () {
                return version;
            },
            os: function () {
                return os;
            },
            isMobile: function () {
                return os !== null;
            }
        }
    });
    
    SMSLink.link = (function () {
        function init() {
            if (!SMSLink.linkDetector) {
                SMSLink.linkDetector = new SMSLink.detector();
            }
        }

        init();
        return {
            replaceAll: function () {
                this.replaceWithSelector("[href^='sms:']");
            },
            replaceWithSelector: function (selector) {
                elements = document.querySelectorAll(selector);
                for (i in elements) {
                    this.replace(elements[i]);
                }
            },
            replace: function (item) {
                if (item.href) {
                    replaceBody = false;
                    switch (SMSLink.linkDetector.os()) {
                        case 'iOS':
                            if (parseFloat(SMSLink.linkDetector.version()) <= 8) {
                                replaceBody = ';';
                            } else {
                                replaceBody = '&';
                            }
                            break;
                        default:
                            break;
                    }
                    if (replaceBody) {
                        item.href = item.href.replace(/\?body=/, replaceBody + 'body=');
                    }
                }
            }
        }
    });

    function replaceLinks(){
        if (/Android|iPhone|iPad/i.test(navigator.userAgent)) {
            link = new SMSLink.link();
            link.replaceWithSelector('.tnt-share-link.sms');

            // Display SMS links
            smsElements = document.querySelectorAll('.social-share-link.sms');
            for (i=0; i<smsElements.length; i++) {
                smsElements[i].style.display = '';
            }

            // Hide print links
            printElements = document.querySelectorAll('.social-share-link.pr');
            for (i=0; i<printElements.length; i++) {
                printElements[i].style.display = 'none';
            }
        }
    }

    if (document.readyState == 'loading') {
        document.addEventListener('DOMContentLoaded', function() {
            replaceLinks();
        });
    } else {
        replaceLinks();
    }
})();